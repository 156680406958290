import * as Yup from 'yup';

export const firstStepperScreenSchema = (validationsMessages) =>
  Yup.object({
    profession: Yup.string().required(validationsMessages.REQUIRED_FIELD),
    workType: Yup.string().required(validationsMessages.REQUIRED_FIELD),
    amount: Yup.string().required(validationsMessages.REQUIRED_FIELD),
    paymentType: Yup.string().required(validationsMessages.REQUIRED_FIELD),
    experienceYears: Yup.number()
      .required(validationsMessages.REQUIRED_FIELD)
      .typeError(validationsMessages.VALID_NUMBER)
      .min(0, validationsMessages.POSITIVE_NUMBER),
    statusAviability: Yup.string().required(validationsMessages.REQUIRED_FIELD),
    skills: Yup.array()
      .min(1, validationsMessages.VALID_SKILLS_LATEST_1)
      .required(validationsMessages.REQUIRED_FIELD),
    region: Yup.string().required(validationsMessages.REQUIRED_FIELD),
    linkedinProfile: Yup.string().matches(/^(http(s)?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,6})(\/[a-zA-Z0-9#-]+\/?)*$/, validationsMessages.VALID_URL_LINKEDIN),
    profileWebsite: Yup.string().matches(/^(http(s)?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,6})(\/[a-zA-Z0-9#-]+\/?)*$/, validationsMessages.VALID_URL_PORTFOLIO),

  });

export const secondStepperScreenSchema = (validationsMessages) =>
  Yup.object({
    postalCode: Yup.string().required(validationsMessages.REQUIRED_FIELD),
    city: Yup.string().required(validationsMessages.REQUIRED_FIELD).matches(/^[a-zA-Z\s\-']+$/, validationsMessages.INVALID_CITY),
    VATNumber: Yup.string().required(validationsMessages.REQUIRED_FIELD),
    freelancersEmails: Yup.string()
      .nullable()
      .matches(
        /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\n[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/,
        validationsMessages.INVALID_EMAIL_LIST
      ),
  });
